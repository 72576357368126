<template>
  <div class="onboarding-survey">
    <!-- Progress Steps -->
    <v-stepper v-model="currentStep" alt-labels class="elevation-0 mb-8">
      <v-stepper-header>
        <v-stepper-step step="1" class="">
          {{ $t("onboarding.steps.personal_data") }}
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="2" class="">
          {{ $t("onboarding.steps.company") }}
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="3" class="">
          {{ $t("onboarding.steps.preferences") }}
        </v-stepper-step>
      </v-stepper-header>
    </v-stepper>

    <!-- Form Content -->
    <div class="form-content mt-4">
      <v-form @submit.prevent="handleSubmit" class="survey-form">
        <!-- Step 1 -->
        <template v-if="currentStep === 1">
          <h1 class="title">{{ $t("onboarding.title") }}</h1>
          <p class="subtitle mb-8">{{ $t("onboarding.subtitle") }}</p>

          <div class="form-group">
            <div class="mb-4 survey-input-label">
              {{ $t("onboarding.form.full_name.label") }}
            </div>
            <v-text-field
              v-model="formData.full_name"
              :rules="[rules.required, rules.fullNameRequired]"
              :placeholder="$t('onboarding.form.full_name.placeholder')"
              outlined
              class="inputs"
            ></v-text-field>
          </div>

          <div class="form-group">
            <div class="mb-4 survey-input-label">
              {{ $t("onboarding.form.sector.label") }}
            </div>
            <v-select
              v-model="formData.sector"
              :items="sectorUserOperateIn"
              :rules="[rules.selectAnOption]"
              :placeholder="$t('common.select')"
              item-text="text"
              item-value="value"
              outlined
              class="inputs"
            ></v-select>
          </div>

          <div class="form-group">
            <div class="mb-4 survey-input-label">
              {{ $t("onboarding.form.find_out.label") }}
            </div>
            <v-select
              v-model="formData.find_out_about"
              :items="findOutAbout"
              :rules="[rules.selectAnOption]"
              :placeholder="$t('common.select')"
              item-text="text"
              item-value="value"
              outlined
              class="inputs"
            ></v-select>
          </div>
        </template>

        <!-- Step 2 -->
        <template v-if="currentStep === 2">
          <div class="form-group">
            <div class="mb-4 survey-input-label">
              {{ $t("onboarding.form.hr_system.label") }}
            </div>
            <v-select
              v-model="formData.hr_system"
              :items="punchClockSystems"
              :rules="[rules.selectAnOption]"
              :placeholder="$t('common.select')"
              outlined
              class="inputs"
            ></v-select>
          </div>

          <div class="form-group">
            <div class="mb-4 survey-input-label">
              {{ $t("onboarding.form.employees_number.label") }}
            </div>
            <v-select
              v-model="formData.employees_number"
              :items="employeesNumberOptions"
              :rules="[rules.selectAnOption]"
              :placeholder="$t('common.select')"
              item-text="text"
              item-value="value"
              outlined
              class="inputs"
            ></v-select>
          </div>

          <div class="form-group">
            <div class="mb-4 survey-input-label">
              {{ $t("onboarding.form.company_sector.label") }}
            </div>
            <v-select
              v-model="formData.company_sector"
              :items="companySectorOptions"
              :rules="[rules.selectAnOption]"
              :placeholder="$t('common.select')"
              item-text="text"
              item-value="value"
              outlined
              class="inputs"
            ></v-select>
          </div>
        </template>

        <!-- Step 3 -->
        <template v-if="currentStep === 3">
          <h2 class="title">
            {{ $t("onboarding.form.interest_areas.label") }}
          </h2>
          <p class="subtitle">
            {{ $t("onboarding.form.interest_areas.subtitle") }}
          </p>

          <div class="form-group mt-10">
            <div class="mb-4 survey-input-label">
              {{ $t("onboarding.form.interest_areas.label") }}
            </div>
            <v-select
              v-model="formData.areas_of_interest"
              :items="interestAreasOptions"
              :rules="[rules.minInterestAreas]"
              :placeholder="$t('onboarding.form.interest_areas.placeholder')"
              item-text="text"
              item-value="value"
              multiple
              chips
              outlined
              class="inputs"
            ></v-select>
          </div>
        </template>

        <!-- Navigation Buttons -->
        <div class="form-navigation">
          <v-btn
            text
            :disabled="currentStep === 1 || loading"
            class="back-btn text-none mr-2"
            @click="goBackstep"
          >
            {{ $t("common.back") }}
          </v-btn>
          <v-btn
            color="primary"
            elevation="0"
            type="submit"
            class="next-btn text-none"
            :loading="loading"
            :disabled="loading || disableNextBtn"
          >
            {{ $t("action.next") }}
          </v-btn>
        </div>
      </v-form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { rules as textFieldRules } from "@/helpers/services/utils";
import { getAnalytics, logEvent } from "firebase/analytics";

export default {
  name: "OnboardingSurvey",
  data() {
    return {
      currentStep: 1,
      loading: false,
      formData: {
        full_name: "",
        sector: "",
        find_out_about: "",
        hr_system: "",
        employees_number: "",
        company_sector: "",
        areas_of_interest: [],
      },

      rules: textFieldRules,

      punchClockSystems: [
        {
          text: this.$t("onboarding.form.hr_system.options.adp"),
          value: "adp",
        },
        {
          text: this.$t("onboarding.form.hr_system.options.sap"),
          value: "sap",
        },
        {
          text: this.$t("onboarding.form.hr_system.options.omie"),
          value: "omie",
        },
        {
          text: this.$t("onboarding.form.hr_system.options.nomus"),
          value: "nomus",
        },
        {
          text: this.$t("onboarding.form.hr_system.options.sankhya"),
          value: "sankhya",
        },
        {
          text: this.$t("onboarding.form.hr_system.options.vrGente"),
          value: "vrGente",
        },
        {
          text: this.$t("onboarding.form.hr_system.options.bambooHR"),
          value: "bambooHR",
        },
        {
          text: this.$t("onboarding.form.hr_system.options.totvs"),
          value: "totvs",
        },
        {
          text: this.$t("onboarding.form.hr_system.options.webmail"),
          value: "webmail",
        },
        {
          text: this.$t("onboarding.form.hr_system.options.workdayHCM"),
          value: "workdayHCM",
        },
        {
          text: this.$t("onboarding.form.hr_system.options.seniorSistemas"),
          value: "seniorSistemas",
        },
        {
          text: this.$t("onboarding.form.hr_system.options.solidesTangerino"),
          value: "solidesTangerino",
        },
        {
          text: this.$t(
            "onboarding.form.hr_system.options.apdataGlobalAntares"
          ),
          value: "apdataGlobalAntares",
        },
        {
          text: this.$t("onboarding.form.hr_system.options.others"),
          value: "others",
        },
      ],

      sectorUserOperateIn: [
        {
          text: this.$t("onboarding.form.sector.options.marketing"),
          value: "marketing",
        },
        {
          text: this.$t("onboarding.form.sector.options.sales"),
          value: "sales",
        },
        {
          text: this.$t("onboarding.form.sector.options.hr"),
          value: "hr",
        },
        {
          text: this.$t("onboarding.form.sector.options.it"),
          value: "IT",
        },
        {
          text: this.$t("onboarding.form.sector.options.design"),
          value: "design",
        },
        {
          text: this.$t("onboarding.form.sector.options.programming"),
          value: "programming",
        },
        {
          text: this.$t("onboarding.form.sector.options.legal"),
          value: "legal",
        },
        {
          text: this.$t("onboarding.form.sector.options.management"),
          value: "management",
        },
        {
          text: this.$t("onboarding.form.sector.options.externalConsultant"),
          value: "externalConsultant",
        },
        {
          text: this.$t("onboarding.form.sector.options.others"),
          value: "others",
        },
      ],
      findOutAbout: [
        {
          text: this.$t("onboarding.form.find_out.options.google_search"),
          value: "google_search",
        },
        {
          text: this.$t("onboarding.form.find_out.options.facebook"),
          value: "facebook",
        },
        {
          text: this.$t("onboarding.form.find_out.options.instagram"),
          value: "instagram",
        },
        {
          text: this.$t("onboarding.form.find_out.options.linkedin"),
          value: "linkedin",
        },
        {
          text: this.$t("onboarding.form.find_out.options.youtube"),
          value: "youtube",
        },
        {
          text: this.$t("onboarding.form.find_out.options.recommended"),
          value: "recommended",
        },
        {
          text: this.$t("onboarding.form.find_out.options.others"),
          value: "others",
        },
      ],

      employeesNumberOptions: [
        {
          text: this.$t("onboarding.form.employees_number.options.up_to_10"),
          value: "up_to_10",
        },
        {
          text: this.$t(
            "onboarding.form.employees_number.options.from_11_to_50"
          ),
          value: "from_11_to_50",
        },
        {
          text: this.$t(
            "onboarding.form.employees_number.options.from_51_to_200"
          ),
          value: "from_51_to_200",
        },
        {
          text: this.$t("onboarding.form.employees_number.options.above_200"),
          value: "above_200",
        },
      ],

      companySectorOptions: [
        {
          text: this.$t("onboarding.form.company_sector.options.technology"),
          value: "technology",
        },
        {
          text: this.$t("onboarding.form.company_sector.options.education"),
          value: "education",
        },
        {
          text: this.$t("onboarding.form.company_sector.options.healthcare"),
          value: "healthcare",
        },
        {
          text: this.$t("onboarding.form.company_sector.options.marketing"),
          value: "marketing",
        },
        {
          text: this.$t("onboarding.form.company_sector.options.finance"),
          value: "finance",
        },
        {
          text: this.$t("onboarding.form.company_sector.options.retail"),
          value: "retail",
        },
        {
          text: this.$t("onboarding.form.company_sector.options.industry"),
          value: "industry",
        },
        {
          text: this.$t("onboarding.form.company_sector.options.construction"),
          value: "construction",
        },
        {
          text: this.$t("onboarding.form.company_sector.options.energy"),
          value: "energy",
        },
        {
          text: this.$t("onboarding.form.company_sector.options.agribusiness"),
          value: "agribusiness",
        },
        {
          text: this.$t("onboarding.form.company_sector.options.logistics"),
          value: "logistics",
        },
        {
          text: this.$t("onboarding.form.company_sector.options.hospitality"),
          value: "hospitality",
        },
        {
          text: this.$t(
            "onboarding.form.company_sector.options.telecommunications"
          ),
          value: "telecommunications",
        },
        {
          text: this.$t(
            "onboarding.form.company_sector.options.foodAndBeverage"
          ),
          value: "foodAndBeverage",
        },
        {
          text: this.$t("onboarding.form.company_sector.options.legal"),
          value: "legal",
        },
        {
          text: this.$t(
            "onboarding.form.company_sector.options.humanResources"
          ),
          value: "humanResources",
        },
        {
          text: this.$t("onboarding.form.company_sector.options.realEstate"),
          value: "realEstate",
        },
        {
          text: this.$t("onboarding.form.company_sector.options.entertainment"),
          value: "entertainment",
        },
        {
          text: this.$t("onboarding.form.company_sector.options.automotive"),
          value: "automotive",
        },
        {
          text: this.$t("onboarding.form.company_sector.options.consulting"),
          value: "consulting",
        },
        {
          text: this.$t("onboarding.form.company_sector.options.fashion"),
          value: "fashion",
        },
        {
          text: this.$t("onboarding.form.company_sector.options.mining"),
          value: "mining",
        },
        {
          text: this.$t("onboarding.form.company_sector.options.environment"),
          value: "environment",
        },
        {
          text: this.$t("onboarding.form.company_sector.options.science"),
          value: "science",
        },
      ],

      interestAreasOptions: [
        {
          text: this.$t(
            "onboarding.form.interest_areas.options.email_signature"
          ),
          value: "email_signature",
        },
        {
          text: this.$t(
            "onboarding.form.interest_areas.options.vacation_message"
          ),
          value: "vacation_message",
        },
        {
          text: this.$t(
            "onboarding.form.interest_areas.options.access_permissions"
          ),
          value: "access_permissions",
        },
        {
          text: this.$t(
            "onboarding.form.interest_areas.options.email_delegation"
          ),
          value: "email_delegation",
        },
        {
          text: this.$t("onboarding.form.interest_areas.options.shift_control"),
          value: "shift_control",
        },
        {
          text: this.$t(
            "onboarding.form.interest_areas.options.vacation_control"
          ),
          value: "vacation_control",
        },
        {
          text: this.$t(
            "onboarding.form.interest_areas.options.email_migration"
          ),
          value: "email_migration",
        },
        {
          text: this.$t("onboarding.form.interest_areas.options.drive_sharing"),
          value: "drive_sharing",
        },
        {
          text: this.$t(
            "onboarding.form.interest_areas.options.secondary_emails"
          ),
          value: "secondary_emails",
        },
        {
          text: this.$t("onboarding.form.interest_areas.options.email_aliases"),
          value: "email_aliases",
        },
        {
          text: this.$t(
            "onboarding.form.interest_areas.options.group_management"
          ),
          value: "group_management",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["token", "currentUser", "company"]),

    disableNextBtn() {
      switch (this.currentStep) {
        case 1:
          return !this.isStepOneValid();
        case 2:
          return !this.isStepTwoValid();
        case 3:
          return !this.isStepThreeValid();
        default:
          return false;
      }
    },
  },
  methods: {
    ...mapMutations(["setCurrentUser"]),

    isStepOneValid() {
      const { full_name, sector, find_out_about } = this.formData;
      const filledFullName =
        full_name.split(" ").filter((word) => word !== "").length >= 2;
      return filledFullName && sector && find_out_about;
    },

    isStepTwoValid() {
      const { hr_system, employees_number, company_sector } = this.formData;
      return hr_system && employees_number && company_sector;
    },

    isStepThreeValid() {
      return this.formData.areas_of_interest.length >= 4;
    },

    goBackstep() {
      if (this.step == 1) return;

      this.currentStep--;

      this.trackUserEvent(
        "clicou pra voltar",
        `clicou pra voltar do passo ${this.currentStep} para o ${
          this.currentStep - 1
        }`
      );
    },

    trackUserEvent(click = "", description = "") {
      const analytics = getAnalytics();
      const { email } = this.currentUser;

      logEvent(analytics, "onboarding_survey", {
        click,
        email,
        description,
      });
    },

    handleSubmit() {
      if (this.currentStep < 3) {
        this.trackUserEvent(
          "clicou pra avançar",
          `clicou pra avança do passo ${this.currentStep} para o ${
            this.currentStep + 1
          }`
        );

        this.currentStep++;
      } else {
        this.trackUserEvent("enviou o formulário");
        this.submitForm();
      }
    },

    setEmployeesNumber() {
      if (!this.company) return;

      const { users_number } = this.company;

      this.formData.employees_number =
        users_number <= 10
          ? "up_to_10"
          : users_number <= 50
          ? "from_11_to_50"
          : users_number <= 200
          ? "from_51_to_200"
          : "above_200";
    },

    submitForm() {
      this.loading = true;
      const url = `${process.env.VUE_APP_API_BASE_URL}/users/survey`;
      const auth = {
        headers: {
          Authorization: this.token,
        },
      };

      this.$axios
        .patch(url, this.formData, auth)
        .then(({ data }) => {
          this.setCurrentUser(data.user);

          if (!this.company) {
            this.$router.push({ name: "Install" });
            return;
          }

          this.$router.push({ name: "Home" });
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },

  created() {
    this.formData.full_name = this.currentUser?.name || "";
    this.setEmployeesNumber();
  },
};
</script>

<style scoped>
.onboarding-survey {
  max-width: 800px;
  margin: 2rem auto;
  padding: 0 1rem;
  font-family: "Poppins", sans-serif;
}

.form-group {
  margin-bottom: 8px;
}
.stepper-bg-color {
  background-color: #f5f5f5;
}

.survey-input-label {
  font-family: Montserrat, sans-serif !important;
  font-weight: 500;
  font-size: 16px;
}

.inputs {
  border-radius: 9px;
}

.inputs .input__control {
  border: 1px solid #adadad;
}

/* CHANGE TEXT FIELD ITEMS FONT */
:deep(.v-input .v-text-field__slot input) {
  font-family: Montserrat, sans-serif !important;
  font-size: 14px !important;
}

:deep(.v-input .v-text-field__slot input::placeholder) {
  font-family: Montserrat, sans-serif !important;
  font-size: 14px !important;
}

/* CHANGE SELECTS ITEMS FONT */
:deep(.v-select .v-select__selections) {
  font-family: Montserrat, sans-serif !important;
  font-size: 14px !important;
}

:deep(.v-select input::placeholder) {
  font-family: Montserrat, sans-serif !important;
  font-size: 14px !important;
}

:deep(.v-stepper__step__step) {
  width: 32px;
  height: 32px;
  margin-right: 0;
  margin-top: -3px;
  border: 1px solid #909090;
  color: #909090 !important;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  background-color: white !important;
}

:deep(.v-stepper__step.v-stepper__step--active .v-stepper__step__step) {
  background-color: #b991ff !important;
  color: white !important;
  border: none;
}

:deep(.v-stepper__step.v-stepper__step--complete .v-stepper__step__step) {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  color: #909090;
}

:deep(.v-stepper__step--complete .v-stepper__label) {
  color: #909090;
  margin-top: 0.1rem !important;
}

:deep(.v-stepper__label) {
  color: #909090;
  font-family: "Poppins", sans-serif;
  font-size: 0.75rem;
  font-weight: 400;
  margin-top: 0.1rem;
}

:deep(.v-stepper__step--active .v-stepper__label) {
  color: #909090;
  margin-top: 0.1rem !important;
}

.form-content {
  max-width: 600px;
  margin: 0 auto;
}

.title {
  font-size: 24px !important;
  font-weight: 600 !important;
  line-height: 42px;
  color: #27272a !important;
  text-align: center;
  font-family: "Poppins", sans-serif !important;
}

.subtitle {
  color: #909090;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  max-width: 550px;
  margin: 8px auto;
}

.form-navigation {
  display: flex;
  justify-content: right;
  margin-top: 2rem;
}

.back-btn {
  font-weight: 400;
  border-radius: 4px;
  min-height: 40px;
  color: #909090;
}

.next-btn {
  background-color: rgb(114, 84, 206, 0.8) !important;
  font-weight: 400;
  border-radius: 4px;
  min-width: 183px !important;
  min-height: 40px;
}
</style>
